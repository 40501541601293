/* eslint no-use-before-define: 0 */ // --> OFF
<template>
  <div ref="scheduler" v-if="haveMachines"></div>
  <div v-else>
    <div class="pa-5">
      Sembra che NON siano ancora state installate macchine in azienda
    </div>
    <div class="pa-5">
      <v-btn color="primary" @click="goToMachines">VAI ALLE MACCHINE</v-btn>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import '@/components/kit/vendors/dhtmlx/dhtmlxscheduler'
import '@/components/kit/vendors/dhtmlx/ext/dhtmlxscheduler_timeline'
import '@/components/kit/vendors/dhtmlx/ext/dhtmlxscheduler_treetimeline'
import { clockToDate } from '@/utils/clockManager'
import { readClock, readCurrentCompanyShops } from '@/modules/match/store/getters'
import { dispatchGetDirectlyCompanyMachines } from '@/modules/company_machines/store/actions'
import { RouteNames } from '@/router/names'

export default Vue.extend({
  name: 'TimelineComponent',
  props: {
    events: {
      type: Array,
      default () {
        // eslint-disable-next-line vue/require-valid-default-prop
        return { events: [] }
      }
    },
    timeSelector: {
      type: String
    }
  },
  data () {
    return {
      companyLayout: [],
      $scheduler: null,
      lastEvents: [],
      haveMachines: false
    }
  },
  computed: {
    currentClock () {
      return readClock(this.$store)
    },
    companyShops () {
      return readCurrentCompanyShops(this.$store)
    }
  },
  methods: {
    async goToMachines () {
      await this.$router.push(
        {
          name: RouteNames.FF_MACHINES
        }
      )
    },
    deleteEvent (eventId) {
      this.$scheduler.deleteEvent(eventId)
    },
    setScaleConfig: function (level) {
      let currentConfig = {}
      switch (level) {
        case 'hour': {
          currentConfig = {
            section_autoheight: false,
            name: 'timeline',
            x_unit: 'minute',
            x_date: '%H:%i',
            x_step: 5,
            x_size: 12,
            x_start: 0,
            x_length: 12,
            y_unit: this.companyLayout,
            y_property: 'section_id',
            render: 'tree',
            folder_dy: 30,
            dy: 60
          }
          break
        }
        case 'day': {
          currentConfig = {
            section_autoheight: false,
            name: 'timeline',
            x_unit: 'hour',
            x_date: '%H:%i',
            x_step: 1,
            x_size: 24,
            x_start: 0,
            x_length: 24,
            y_unit: this.companyLayout,
            y_property: 'section_id',
            render: 'tree',
            folder_dy: 30,
            dy: 60
          }
          break
        }
        case 'week': {
          currentConfig = {
            section_autoheight: false,
            name: 'timeline',
            x_unit: 'day',
            x_date: '%d %M',
            x_step: 1,
            x_size: 7,
            x_start: 0,
            x_length: 7,
            y_unit: this.companyLayout,
            y_property: 'section_id',
            render: 'tree',
            folder_dy: 30,
            dy: 60
          }
          break
        }
        case 'month': {
          break
        }
      }
      this.$scheduler.createTimelineView(currentConfig)
    },
    async initTimeline () {
      this.$scheduler = window.Scheduler.getSchedulerInstance()
      this.$scheduler.skin = 'material'
      this.$scheduler.locale.labels.timeline_tab = 'Timeline'
      this.$scheduler.locale.labels.section_custom = 'Section'
      this.$scheduler.config.details_on_create = false
      this.$scheduler.config.details_on_dblclick = false
      this.$scheduler.config.readonly = true
      this.$scheduler.config.header = [
        'date',
        'prev',
        'next'
      ]

      // carico i macchinari legati al reparto selezionato
      const allMachines = await dispatchGetDirectlyCompanyMachines(this.$store)
      const shops = readCurrentCompanyShops(this.$store)
      shops.forEach(e => {
        const children = []
        const machines = e.machines
        machines.forEach(m => {
          debugger
          const companyMachine = allMachines.find(item => item.machine_id === m.id)
          if (companyMachine && companyMachine.is_purchased) {
            children.push(
              {
                key: m.id,
                label: m.name
              }
            )
          }
        })
        this.companyLayout.push(
          {
            key: 'shop-' + e.shop.id,
            label: e.shop.name,
            open: true,
            children: children
          }
        )
      })

      // config DAY
      this.$scheduler.createTimelineView({
        section_autoheight: false,
        name: 'timeline',
        x_unit: 'hour',
        x_date: '%H:%i',
        x_step: 1,
        x_size: 24,
        x_start: 0,
        x_length: 24,
        y_unit: this.companyLayout,
        y_property: 'section_id',
        render: 'tree',
        folder_dy: 30,
        dy: 60,
        dx: 300
      })

      this.$scheduler.config.lightbox.sections = [
        { name: 'description', height: 50, map_to: 'text', type: 'textarea', focus: true },
        { name: 'custom', height: 30, type: 'timeline', options: null, map_to: 'section_id' }, // type should be the same as name of the tab
        { name: 'time', height: 72, type: 'time', map_to: 'auto' }
      ]

      const actualDate = clockToDate(this.currentClock)
      debugger
      this.$scheduler.init(this.$refs.scheduler, actualDate, 'timeline')

      this.$scheduler.attachEvent('onClick', function (id, e) {
        const event = this.$scheduler.getEvent(id)
        alert(event.text)
        return true
      })
    },
    renderProductionOrders (newVal) {
      debugger
      if (!newVal) return
      console.log('eventi cambiati')
      this.$scheduler.clearAll()
      this.$scheduler.parse(newVal)
      console.log(newVal)

      this.$scheduler.render()
      this.lastEvents = newVal
    }
  },
  async mounted () {
    const allMachines = await dispatchGetDirectlyCompanyMachines(this.$store)
    this.haveMachines = false
    debugger
    if (allMachines.some(item => item.is_purchased === true)) {
      this.haveMachines = true
    }
    if (this.haveMachines) {
      this.initTimeline()
    }
  },
  watch: {
    companyShops: {
      handler (val) {
        debugger
        const shops = val
        shops.forEach(e => {
          const children = []
          const machines = e.machines
          machines.forEach(m => {
            children.push(
              {
                key: m.id,
                label: m.name
              }
            )
          })
          this.companyLayout.push(
            {
              key: 'shop-' + e.shop.id,
              label: e.shop.name,
              open: true,
              children: children
            }
          )
        })
        this.$scheduler.createTimelineView({
          section_autoheight: false,
          name: 'timeline',
          x_unit: 'hour',
          x_date: '%H:%i',
          x_step: 1,
          x_size: 24,
          x_start: 0,
          x_length: 24,
          y_unit: this.companyLayout,
          y_property: 'section_id',
          render: 'tree',
          folder_dy: 30,
          dy: 35,
          dx: 300
        })
        this.initTimeline()
        this.renderProductionOrders(this.lastEvents)
      }
    },
    timeSelector: function (newVal, oldVal) {
      if (!newVal) return
      if (newVal !== oldVal) {
        this.setScaleConfig(newVal)
      }
    },
    events: function (newVal, oldVal) {
      debugger
      this.renderProductionOrders(newVal)
    }
  }
})
</script>

<style>
.no-machine-text {
  width: 100%;
  text-align: center;
}
</style>
