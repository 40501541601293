
import Vue from 'vue'
import { IHeaderOption, KwikDialog, KwikMediumContainer, KwikPageToolbar, KwikTableAction, NumberNullable } from 'kwik-vue'
import { readClock, readCurrentCompanyShops } from '@/modules/match/store/getters'
import { ICompanyShop } from '@/shared/types/match'
import { dispatchGetBoMByCompanyShopId, dispatchGetBoMByMasterParentId } from '@/modules/bill_of_materials/store/actions'
import { IBatchesForProductionGetInput, IBatchForProductionItem, ICompanyBoMMaterial, IInputMaterial, IMachine, IProductionOrderCreatePayload, IProductionOrderInputBatchPayload } from '../types'
import { dispatchGetBatchesForProduction, dispatchGetProductionOrderDuration, dispatchGetProductionOrderForTimeline, dispatchInsertProductionOrder } from '../store/actions'
import { clockToDate, clockToFormatDate } from '@/utils/clockManager'
import { RouteNames } from '@/router/names'
import { dispatchGetDirectlyCompanyMachines, dispatchGetShopCleaning, dispatchGetShopSetup } from '@/modules/company_machines/store/actions'
import { ICompanyMachine, ICompanyShopCleaning, ICompanyShopSetup } from '@/modules/company_machines/types'
import FFDateTimePicker from '@/components/FFDateTimePicker.vue'
import TimelineComponent from '@/components/timeline/TimelineComponent.vue'
import { commitKwikAddNotification } from 'kwik-vue/store/main/mutations'

export default Vue.extend({
  name: 'FFNewProductionOrder',
  components: { KwikMediumContainer, KwikDialog, KwikPageToolbar, KwikTableAction, FFDateTimePicker, TimelineComponent },
  data () {
    return {
      options: {} as any,
      loading: true,
      showSelectOperation: false,
      selectedOperation: null as ICompanyShop | null,
      companyShops: [] as ICompanyShop[],
      shopMaterials: [] as ICompanyBoMMaterial[],
      selectedMaterial: null as ICompanyBoMMaterial | null,
      showSelectMaterial: false,
      inputMaterials: [] as IInputMaterial[],
      currentInputMaterial: null as IInputMaterial | null,
      batchesForProduction: [] as IBatchForProductionItem[],
      currentBatchesForProduction: [] as IBatchForProductionItem[],
      currentBatchesLoadedQuantity: 0,
      currentBatchesTargetQuantity: 0,
      currentBatchesTitle: '',
      inputComplete: false,
      showFillInputBatch: false,
      showCommit: false,
      currentQuantity: 0,
      currentCommitBatch: null as IBatchForProductionItem | null,
      showSelectMachine: false,
      selectedMachine: null as IMachine | null,
      companyMachines: [] as ICompanyMachine[],
      shopCleaningOptions: [] as ICompanyShopCleaning[],
      shopSetupPackingOptions: [] as ICompanyShopCleaning[],
      shopSetupFormingOptions: [] as ICompanyShopCleaning[],
      selectedCleaningId: null as NumberNullable,
      selectedSetupPackingId: null as NumberNullable,
      selectedSetupFormingId: null as NumberNullable,
      startClock: 0,
      showTimeline: false,
      events: [] as any[],
      startDuration: '2222',
      endDuration: '2223',
      isOverlapping: false,
      breadcrumbs: [
        { text: 'Food Factory', disabled: true },
        { text: 'Ordini di produzioni', disabled: false }
      ]
    }
  },
  computed: {
    inputMaterialsHeaders () : IHeaderOption[] {
      const defaultHeaders = [
        {
          text: 'Materiale',
          sortable: true,
          value: 'materialName',
          hidden: false,
          align: 'start'
        },
        {
          text: 'Quantità richiesta',
          sortable: true,
          value: 'quantity',
          hidden: false,
          align: 'start'
        },
        {
          text: 'Azioni',
          sortable: true,
          value: 'actions',
          hidden: false,
          align: 'start'
        }
      ] as IHeaderOption[]
      return defaultHeaders
    },
    batchesHeaders (): IHeaderOption[] {
      const defaultHeaders = [
        {
          text: 'Codice',
          sortable: true,
          value: 'batch_code',
          hidden: false,
          align: 'start'
        },
        {
          text: 'Scadenza',
          sortable: true,
          value: 'expiration_clock',
          hidden: false,
          align: 'start'
        },
        {
          text: 'Disponibile',
          sortable: true,
          value: 'available',
          hidden: false,
          align: 'start'
        },
        {
          text: 'In produzione',
          sortable: true,
          value: 'total_quantity',
          hidden: false,
          align: 'start'
        },
        {
          text: this.$t('commons.buttons.actions'),
          value: 'actions',
          sortable: false,
          hidden: false
        }
      ] as IHeaderOption[]
      return defaultHeaders
    }
  },
  methods: {
    clockToFormatDate (clock: number) {
      return clockToFormatDate(clock, 'shortDateTime')
    },
    startSelectOperation () {
      this.companyShops = readCurrentCompanyShops(this.$store)
      this.showSelectOperation = true
    },
    async setOperation (companyShop: ICompanyShop) {
      this.selectedOperation = companyShop
      this.showSelectOperation = false
      // carico i macchinari legati al reparto selezionato
      const allMachines = await dispatchGetDirectlyCompanyMachines(this.$store)
      const filteredMachines = allMachines.filter((e:ICompanyMachine) => e.is_purchased === true && e.company_shop_id === companyShop.id)
      this.companyMachines = filteredMachines
      // carico i dati di cleaning e setup
      const cleanings = await dispatchGetShopCleaning(this.$store, companyShop.id)
      const setups = await dispatchGetShopSetup(this.$store, companyShop.id)

      if (!cleanings) {
        this.shopCleaningOptions = []
      } else {
        this.shopCleaningOptions = cleanings.filter((e: ICompanyShopCleaning) => e.cleaning_code === 'PULIZIA')
      }
      if (!setups) {
        this.shopSetupPackingOptions = []
        this.shopSetupFormingOptions = []
      } else {
        this.shopSetupPackingOptions = setups.filter((e: ICompanyShopSetup) => e.setup_code === 'ATTEZZAGGIO_PACKING')
        this.shopSetupFormingOptions = setups.filter((e: ICompanyShopSetup) => e.setup_code === 'ATTEZZAGGIO_FORMATURA')
      }

      // carico i dati dei materiali creabili nella fase selezionata
      this.shopMaterials = []
      const companyBoMs = await dispatchGetBoMByCompanyShopId(this.$store, this.selectedOperation.id)
      if (!companyBoMs) {
        alert('errore, qualcosa è andato storto')
        return
      }

      for (const companyBoM of companyBoMs) {
        // matteo: possono esserci dei duplicati
        const materialId = companyBoM.bill_of_material_master.material.id
        const duplicated = this.shopMaterials.find(t => t.materialId === materialId)
        if (duplicated) continue
        const shopMaterial: ICompanyBoMMaterial = {
          materialId: materialId,
          materialName: companyBoM.bill_of_material_master.material.name,
          bomMasterId: companyBoM.bill_of_material_master_id
        }
        this.shopMaterials.push(shopMaterial)
      }
    },
    startSelectMaterial () {
      this.showSelectMaterial = true
    },
    async setMaterial (material: ICompanyBoMMaterial) {
      this.selectedMaterial = material
      this.inputMaterials = []
      const inputBoMs = await dispatchGetBoMByMasterParentId(this.$store, this.selectedMaterial.bomMasterId)
      if (!inputBoMs) {
        alert('errore, qualcosa è andato storto')
        return
      }

      for (const inputBoM of inputBoMs) {
        if (inputBoM.is_excluded) continue
        const inputMaterial: IInputMaterial = {
          materialId: inputBoM.material.id,
          materialName: inputBoM.material.name,
          quantity: inputBoM.unit_quantity,
          filledQuantity: 0
        }
        this.inputMaterials.push(inputMaterial)
      }
      this.showSelectMaterial = false
    },
    async openFillBatchActions (inputMaterial: IInputMaterial) {
      this.showFillInputBatch = true
      const payload: IBatchesForProductionGetInput = {
        material_id: inputMaterial.materialId
      }
      const loadedBatches = await dispatchGetBatchesForProduction(this.$store, payload)
      this.currentBatchesForProduction = []
      for (const batch of loadedBatches) {
        const candidateBatch: IBatchForProductionItem = {
          batch_id: batch.id,
          batch_code: batch.code,
          used_quantity: batch.used_quantity,
          total_quantity: batch.total_quantity,
          available_quantity: batch.total_quantity - batch.used_quantity,
          commit_quantity: 0,
          expiration_clock: batch.expiration_clock
        }
        this.currentBatchesForProduction.push(candidateBatch)
      }
      this.currentBatchesTargetQuantity = inputMaterial.quantity
      this.currentBatchesTitle = inputMaterial.materialName
      this.currentInputMaterial = inputMaterial
    },
    loadBatchActions (batch: IBatchForProductionItem) {
      this.currentCommitBatch = batch
      // preimposto la quantità per evitare inputazioni inutili
      const needQuantity = this.currentBatchesTargetQuantity - this.currentBatchesLoadedQuantity
      if (needQuantity > batch.available_quantity) {
        this.currentQuantity = batch.available_quantity
      } else {
        this.currentQuantity = needQuantity
      }

      this.showCommit = true
    },
    commitQuantity () {
      debugger
      if (!this.currentCommitBatch) return
      const batchForProduction = this.currentBatchesForProduction.find(e => e.batch_code === this.currentCommitBatch?.batch_code)
      if (!batchForProduction) return
      const currentQuantity: number = +this.currentQuantity
      const updatedAvailable = batchForProduction.total_quantity - batchForProduction.used_quantity
      if (updatedAvailable < currentQuantity) {
        commitKwikAddNotification(this.$store, {
          content: 'La quantità richiesta NON è disponibile sul lotto',
          color: 'error'
        })
        this.showCommit = false
        return
      }

      batchForProduction.commit_quantity = currentQuantity
      batchForProduction.used_quantity += currentQuantity
      this.currentBatchesLoadedQuantity += currentQuantity
      this.showCommit = false
    },
    closeCommitQuantity () {
      // TODO Matteo vanno puliti i lotti committati
      this.showCommit = false
    },
    commitMaterial () {
      for (const batch of this.currentBatchesForProduction) {
        if (batch.commit_quantity > 0) {
          this.batchesForProduction.push(batch)
        }
      }
      this.currentBatchesForProduction = []
      const inputMaterial = this.inputMaterials.find(e => e.materialId === this.currentInputMaterial?.materialId)
      if (!inputMaterial) return
      inputMaterial.filledQuantity = inputMaterial.quantity
      this.currentInputMaterial = null
      this.currentBatchesLoadedQuantity = 0
      this.showFillInputBatch = false
    },
    closeCommitMaterial () {
      this.currentInputMaterial = null
      this.currentBatchesLoadedQuantity = 0
      this.showFillInputBatch = false
    },
    startSelectMachine () {
      this.showSelectMachine = true
    },
    setMachine (machine: IMachine) {
      this.selectedMachine = machine
      this.showSelectMachine = false
      const clock = readClock(this.$store)
      console.log(clock)
      this.startClock = clock
      this.getDuration()
    },
    async cancel () {
      await this.$router.push(
        {
          name: RouteNames.FF_PRODUCTION_ORDERS
        }
      )
    },
    reset () {
      this.showSelectOperation = false
      this.selectedOperation = null
      this.companyShops = []
      this.shopMaterials = []
      this.selectedMaterial = null
      this.showSelectMaterial = false
      this.inputMaterials = []
      this.currentInputMaterial = null
      this.batchesForProduction = []
      this.currentBatchesForProduction = []
      this.currentBatchesLoadedQuantity = 0
      this.currentBatchesTargetQuantity = 0
      this.currentBatchesTitle = ''
      this.inputComplete = false
      this.showFillInputBatch = false
      this.showCommit = false
      this.currentQuantity = 0
      this.currentCommitBatch = null
      this.showSelectMachine = false
      this.selectedMachine = null
      this.companyMachines = []
      this.shopCleaningOptions = []
      this.shopSetupPackingOptions = []
      this.shopSetupFormingOptions = []
      this.isOverlapping = false
    },
    async confirm () {
      debugger
      if (!this.selectedMaterial) return
      if (!this.selectedMachine) return
      if (!this.selectedOperation) return
      const batches = [] as IProductionOrderInputBatchPayload[]
      for (const batch of this.batchesForProduction) {
        const batchToPush: IProductionOrderInputBatchPayload = {
          batch_id: batch.batch_id,
          quantity: batch.commit_quantity
        }
        batches.push(batchToPush)
      }
      debugger
      const createPayload: IProductionOrderCreatePayload = {
        batches: batches,
        start_clock: this.startClock,
        cleaning_id: this.selectedCleaningId,
        forming_setup_id: this.selectedSetupFormingId,
        packing_setup_id: this.selectedSetupPackingId,
        material_id: this.selectedMaterial.materialId,
        company_shop_id: this.selectedOperation.id,
        company_shop_machine_id: this.selectedMachine.id
      }
      if (this.startClock < readClock(this.$store)) {
        commitKwikAddNotification(this.$store, {
          content: 'La data di produzione deve essere superiore alla data attuale',
          color: 'error'
        })
        return
      }
      await dispatchInsertProductionOrder(this.$store, createPayload)
      await this.$router.push(
        {
          name: RouteNames.FF_PRODUCTION_ORDERS
        }
      )
    },
    toggleTimeline () {
      this.showTimeline = !this.showTimeline
      if (this.showTimeline) {
        this.loadTimelineData()
      }
    },
    async loadTimelineData () {
      const productionOrders = await dispatchGetProductionOrderForTimeline(this.$store)
      debugger
      const items = [] as any[]
      productionOrders.forEach((e: any) => {
        if (e.start_clock !== e.end_clock) {
          const startTime = clockToDate(e.start_clock)
          const endTime = clockToDate(e.end_clock)
          const description = `[ ${e.id} ] - ${e.material.name} #Lot: ${e.produced_batch_id}`
          items.push(
            {
              productionOrderId: e.id,
              start_date: startTime,
              end_date: endTime,
              text: description,
              section_id: e.machine.id
            }
          )
        }
      })
      debugger
      this.events = items
    },
    async getDuration () {
      if (!this.selectedMaterial) return
      if (!this.selectedMachine) return
      if (!this.selectedOperation) return
      const durationPayload: IProductionOrderCreatePayload = {
        start_clock: this.startClock,
        cleaning_id: this.selectedCleaningId,
        forming_setup_id: this.selectedSetupFormingId,
        packing_setup_id: this.selectedSetupPackingId,
        material_id: this.selectedMaterial.materialId,
        company_shop_id: this.selectedOperation.id,
        company_shop_machine_id: this.selectedMachine.id,
        batches: []
      }
      const durationData = await dispatchGetProductionOrderDuration(this.$store, durationPayload)
      this.startDuration = this.clockToFormatDate(durationData.start_clock)
      this.endDuration = this.clockToFormatDate(durationData.end_clock)
      this.isOverlapping = durationData.is_overlapping
    }
  },
  watch: {
    inputMaterials: {
      handler (val: IInputMaterial[]) {
        // debugger
        if (this.selectedMaterial === null) {
          this.inputComplete = false
          return
        }

        let completed = true
        for (const inputMaterial of val) {
          if (inputMaterial.quantity !== inputMaterial.filledQuantity) {
            completed = false
            break
          }
        }
        this.inputComplete = completed
      },
      deep: true
    },
    selectedSetupPackingId: {
      async handler () {
        this.getDuration()
      }
    },
    selectedSetupFormingId: {
      async handler () {
        this.getDuration()
      }
    },
    selectedCleaningId: {
      async handler () {
        this.getDuration()
      }
    },
    startClock: {
      async handler () {
        this.getDuration()
      }
    }
  }
})
